.commentaires {
padding:5px 5px 5px 5px;
clear:both;
margin-top:5px;
}

.commentaire{
margin-bottom:20px;
padding-bottom: 20px;
}

.more_comment{
padding:5px 10px;
background:#F5F5F5;
}

.more_comment_lien{
background:#F5F5F5;
color:#333333;
font-size:11px;
text-decoration:none;
padding-left:30px;
line-height:30px;
display:block;
}

.commentaires h3{

}

.commentaire_form{
padding:5px 5px 5px 5px;
clear:both;
}

.commentaire_form h3{
line-height:40px;
}

#disposition2 textarea[name='ccommentaire']{
width:200px;
}

.table_commentaire{
background:#F5F5F5;
}

.commentaire_item{
clear:both;
float:left;
padding:10px 0px 0px 5px;
}

.commentaire_date{
color: #7E7E7E;
margin: 0 0 5px 90px;
padding: 0;
}

.commentaire_auteur{
font-weight:bold;
color:#1F558D;
}

.commentaire_meta{
float: left;
}

.commentaire_content{
background:#F0F0F0 url('@{img-path-from-module}/commentaires.png') no-repeat top left;
margin-left: 70px;
padding: 5px 5px 12px 18px;
width:80%;
}

.commentaire_content p{
text-align:justify;
padding:5px;
}
