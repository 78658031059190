.errorBody{
  background:#ebebeb;

  .container{
    background:#ffffff;
    border-radius: 5px;
    padding:10px;
    margin-top:50px;
  }

  .title{
    font-size:18px;
  }

  .red{
    color:#F44336;
  }
}
