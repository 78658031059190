.navbar{
	margin:0;
}

.navbar-default{
	background:@heading-bg;
	border:none;
}

.navbar-default .navbar-nav > li.home > a{
background: url("@{img-path}/ico_home.png") no-repeat scroll 10px center rgba(0, 0, 0, 0);
padding: 30px 0 15px 55px;
}

.navbar-default .navbar-nav > li > a{
	color:@navbar-text-color;
	font-size: 18px;
	text-transform: uppercase;
}

.navbar-default .navbar-nav > li > a:hover{
	color:@navbar-text-color-hover;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus{
	background:#222222;
	color:#fff;
}

.dropdown-menu{
	background:#222222;
	border:none;
}

.dropdown-menu > li > a{
	color:#ffffff;
  padding:10px 20px;
}

.dropdown-menu > li > a:hover{
	background:#333333;
	color:#ffffff;
}

.caret{
	margin-left:5px;
}

.navbar-header{
	line-height: 50px;
	color:#ffffff;
	font-size: 18px;
	text-transform: uppercase;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
	background-color: #666666;
}

.navbar-default .navbar-toggle{
	border-color:#333333;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form{
	border-color:#333333;
}

@media(min-width:768px){

	.navbar-default .navbar-nav > li > a{
		font-size:16px;
	}

	.navbar-header{
		display: none;
	}

	.navbar-collapse{
		padding:0;
	}

	.navbar-nav{
		float:right;
	}
}

@media(min-width:992px){

	.navbar-default .navbar-nav > li > a{
		font-size:16px;
	}
}

@media(min-width:1200px){

	.navbar-default .navbar-nav > li > a{
		font-size:18px;
	}

}
