.post-list, .list, .relatedPosts{
  list-style-type:none;
  margin: 0;
  padding: 0;
}

.relatedPosts li{
  display:inline-block;
}

.post{
  margin-bottom: 20px;
}

.entry-meta{
  font-size: 12px;
}
