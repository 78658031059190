/* Style les liens vers pièces jointes avec icone selon extension */
a.iconfile{
background:url('@{global-img-path}/Icone_type_fichier/page_white.png') no-repeat center left;;
display:block;
text-align:left;
margin:0;
padding: 0px 0px 0px 30px;
}
a.iconfile[href$=".pdf"]{
background:url('@{global-img-path}/Icone_type_fichier/page_white_acrobat.png') no-repeat center left;
}
a.iconfile[href$=".ppt"]{
background:url('@{global-img-path}/Icone_type_fichier/page_white_powerpoint.png') no-repeat center left;
}
a.iconfile[href$=".xls"],a.iconfile[href$=".xlsx"]{
background:url('@{global-img-path}/Icone_type_fichier/page_white_excel.png') no-repeat center left;
}
a.iconfile[href$=".doc"],a.iconfile[href$=".docx"],a.iconfile[href$=".odt"]{
background:url('@{global-img-path}/Icone_type_fichier/page_word.png') no-repeat center left;
}
