.article{
float:left;
width:100%;
}

.article h1{
color:#296482;
font-weight:normal;
font-size:30px;
margin:5px 0px;
padding:0px;
border-bottom:solid 2px #dddddd;
}

.article .contenu{
float:left;
padding:0px 20px;
}

.article p{
margin:10px 0px;
text-align:justify;
}

.article .chapeau{
float:left;
width:100%;
}

.article .chapeau p{
font-size:14px;
font-weight:bold;
}

.article .zone1{
float:left;
width:100%;
margin:10px 0px;
}

.article .zone1 img{
float:left;
display:block;
background:#000000;
margin:10px 20px 10px 0px;
width:320px;
}

.article .zone2{
float:left;
width:100%;
margin:10px 0px;
}

.article .zone2 img{
float:right;
display:block;
background:#000000;
margin:10px 0px 10px 20px;
width:320px;
}

.article .informations{
float:left;
width:100%;
margin:10px 0px;
}

.article .informations p{
font-size:12px;
font-weight:normal;
text-align:right;
}

.article .informations span.redacteur{
font-size:12px;
font-weight:bold;
}

.bloc_telechargement{
clear:both;float:left;width:100%;background:#f5f5f5;padding:15px;margin:0px 0px 20px 0px;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;
}
.bloc_telechargement h2{
font-family:Arial, Verdana;font-size:16px;line-height:30px;color:#444444;margin:0px 0px 10px 0px;
}
.bloc_telechargement p{
font-size:14px;color:#444444;margin:0px 0px 10px 0px;padding:0px 0px 0px 20px;line-height:20px;
}
.bloc_telechargement p a{
font-size:14px;color:#444444;text-decoration:none;
}
.bloc_telechargement p a:hover{
text-decoration:underline;
}


/******************* MISE EN PAGE DES ARTICLES DANS LES PAGES *******************/

.disposition10 h2{
margin-top:0;
}

.document {
padding:5px 5px 5px 5px;
clear:both;
}

.document p {
line-height: 30px;
font-size: 14px;
margin: 10px 0;
}

.document .img_article {
  margin-top: 20px;
  padding: 0 10px 10px 0;
}
.document p.btn-read-more {
  text-align: right;
}
.document a.btn-primary{
  background: transparent;
  border:2px solid @color-primary;
  font-size: 20px;
  color:@color-primary;
  padding:5px 30px;
}

.document a.btn-primary:hover{
  background: @color-primary;
  border:2px solid @color-primary;
  color:#ffffff;
}

.share-twitter,
.share-google,
.share-fb,
.share-viadeo,
.share-linkedin {
  color: #333;
}
.share-twitter:hover {
  color: #4099FF;
}
.share-google:hover {
  color: #d34836;
}
.share-fb:hover {
  color: #3B5998;
}
.share-linkedin:hover {
  color: #0177B5;
}
.share-viadeo:hover {
  color: #f07355;
}
.document_item{
clear:both;
float:left;
padding:10px 0px 0px 5px;
}

.document_item p{
text-align:justify;
}

.document_item h2{
color:#000000;
font-weight:bold;
font-size:14px;
padding:0px;
display:inline;
}

.document_item a{
font-size:12px;
}

.document_item img{
border:solid 1px #dbdbdb;
}

a.lien_abstract{
font-size:11px;
}

.texte_document{
clear:both;
padding:5px 0px 5px 0px;
}

.texte_document p, .texte_document ul li{
font-size: 13px;
}

p.titre_rubrique {
  font-family:open_sansregular;
  color:#000000;
  font-size:24px;
  font-weight:bold;
}
.col_article_thumb{
  margin-bottom: 20px;
}

.article_thumb_img{
  border:1px solid #cccccc;
  padding:20px;
  text-align: left;
  border-radius: 5px;
}

.article_thumb_img .article_img{
  margin:5px 10px;
}

.article_thumb_img img{
  width: 223px;
  height: 179px;
}

.article_thumb p.h2{
  font-family:@font1;
  color:@color1;
  font-size:24px;
  font-weight:normal;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: left;
}

.article_thumb p.h2{
  font-family:@font1;
  color:@color1;
  font-size:24px;
  font-weight:normal;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: left;
}

.article_thumb p.h2 span.nom{
  text-transform: uppercase;
}

.article_thumb p.h2 span.date{
  font-size: 14px;
}

.article_thumb p{
  font-size:14px;
}

.article_thumb .article_infos{
  min-height: 20px;
}

.btn_article_thumb{
  position: absolute;
  right: 5px;
  bottom:-10px;
}

.disposition_tiles h1{
  font-family: @font3;
}

.disposition_tiles .tile:nth-child(2n+1) {
  clear: both;
}

.tile_intermediaire{
  border: 1px solid #ddd;
  padding: 30px;
  margin-bottom: 30px;

  h2 {
    font-family: @font3;
    font-size: 20px;
  }

  img {
    max-height: 310px;
    max-width: 100%;
    border-radius: 10px;
  }
}

@media (min-width: 992px) {
  .tile_intermediaire {
    min-height: 550px;
  }
}