.side_menu{
	margin:0px 0px 20px 0px;
}

.side_menu ul{
	list-style:none;
	margin:0;
	padding:0;
}

.side_menu > ul, .side_menu > ul > li > a, .side_menu ul ul{
	border-width: 0px;
}

.side_menu > ul {
	border-style: solid;
}

.side_menu > ul ul {
	padding:5px;
}

.side_menu ul li {
	margin:0 0 10px 0;
}

.side_menu ul li li {
	margin:0;
}

.side_menu > ul > li > a, .side_menu ul ul {
	border-bottom-style: solid;
}

.side_menu > ul > li:last-child > a {
	border-bottom-style: none;
}

.side_menu ul li a {
	color: #000000;
	padding: 8px 8px 8px 15px;
	background-color: #e5e5e5;
	display: block;
	font-size: 15px;
	transition: background-color 200ms linear;
	text-decoration: none;
	border-radius: 5px;
}

.side_menu ul li li a {
	color: #555555;
}

.side_menu ul li a:hover{
	background-color: #000000;
	color: white;
	border-radius: 5px;
}

.side_menu ul li li a {
	font-size:13px;
	padding-left: 25px;
	background: transparent;
}

.side_menu ul li li a:hover,
.side_menu .selected .selected > a:hover{
	color:#ffffff;
	border-radius: 5px;
}

.side_menu .selected {
	border-radius: 5px;
}

.side_menu .selected > a {
	background:#333333;
	color:#ffffff;
	font-weight: bold;
}

.side_menu .selected .selected > a {
	font-weight: bold;
	color:#000000;
}

.titre_menu p.h2 {
  font-family:open_sansregular;
  color:#000000;
  font-size:24px;
  font-weight:bold;
}
