/*---------------------------MOTEUR DE RECHERCHE---------------------------*/
#recherche input[type="text"]{

padding: 5px 10px 5px;
height: 32px;
border: none;
-webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08); box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
-webkit-border-radius: 3px;
-moz-border-radius: 3px;
border-radius: 3px;

color: #3a3a3a;
font-family: Arial, sans-serif;
font-size: 16px;
line-break: 20px;
line-height:27px;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
}

.onglet_recherche{
width:150px;
padding:10px;
margin-right:10px;
}

.onglet_recherche_selected{
border-top:solid 1px #dddddd;
border-left:solid 1px #dddddd;
border-right:solid 1px #dddddd;
background:#ffffff;
width:150px;
padding:10px;
margin:5px;
}

.lien_onglet_recherche{
color:#296482;
font-size:12px;
text-decoration:none;
font-weight:bold;
}

.table_form_recherche{
background:#ffffff;
}

.recherche{
	background: #f2f2f2;
	border:1px solid #f2f2f2;
	border-radius:5px;
}

.col_recherche{
	margin-right:0;
	padding-right:0;
}

.recherche input[type="text"]{
	border:none;
	box-shadow: none;
	height:40px;
	float:left;
	background: #f2f2f2;
}

.recherche .btn-search{
	background:#f2f2f2 url('@{img-path}/ico_search.png') no-repeat center center;
	height:40px;
	width: 40px;
	border:none;
	float:right;
	box-shadow:none;
}

.recherche_resultats h2{
margin:3px 0px;
padding:0;
font-size:14px;
color:#000000;
}
