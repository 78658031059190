body{
	font-family:open_sansregular;
}

h1, .h1{
	font-family:open_sansregular;
	color:@color1;
	font-size:42px;
	font-weight:bold;
}

h2, .h2{
	font-family:open_sansregular;
	color:#000000;
	font-size:24px;
	font-weight:bold;
}

h3, .h3{
	font-family:open_sansregular;
	color:#000000;
	font-size:18px;
	font-weight:bold;
}

a{
	color:@color1;
}

a:hover{
	color:@color2;
}

.texte {
font-size:11px;
}
.libelle {
color:#000000;
font-size:12px;
font-weight:bold;
}
