#panier{
font-size: 12px;
padding:0px 20px 0px 30px;
color:#908f90;
font-weight:bold;
font-size:12px;
margin-left: 10px;
float: left;
width:150px;
}
