/*---------------------------FIL ARIANE---------------------------*/

#ariane{
clear:both;
color:#31579e;
font-size:12px;
display:block;
width:980px;
padding:12px 0px 3px 0px;
height:15px;
line-height:15px;
}

.div_ariane{
float:left;
}
#ariane a {
font-size:12px;
color:#31579e;
text-decoration:none;
}

#ariane_fil{
float:left;
}

#ariane_outils{
float:right;
}

#ariane_outils img {
vertical-align:middle;
padding:0px 5px 0px 0px;
}

#ariane_outils a {
padding:0px 0px 0px 10px;
font-size:12px;
color:#31579e;
}
