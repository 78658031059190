h1.plan_niveau {
border-bottom: 2px solid @color1;
color: @color1;
font-size: 16px;
font-weight: normal;
margin: 10px 0;
line-height: 32px;
}


h1.plan_niveau a, h1.plan_niveau a:hover {
color: @color1;
text-decoration: none;
}


h2.plan_niveau {
border: 0 none;
color: @color2;
font-size: 12px;
font-weight: normal;
margin: 3px 0;
padding-left: 20px;
line-height: 1.5;
}


h2.plan_niveau a, h2.plan_niveau a:hover {
color: @color2;
text-decoration: none;
}

h3.plan_niveau {
color:@color1;
font-size:10px;
font-weight:normal;
margin:2px 0;
padding-left:40px;
border:0px;
}

#plan_site ul {
list-style-image:none;
list-style-position:outside;
list-style-type:none;
}
