/* Profil */
.profil_name{
	font-size: 18px;
	color:#444444;
}

.profil_photo{
	width:100px;
	display:inline-block;
	vertical-align:top;
}

.profil_infos{
	font-size:12px;
	margin-left: 10px;
	display:inline-block;
	vertical-align:top;
}

.profil_link{
	text-decoration: none;
	font-weight: bold;
}

.profil_link:hover{
	text-decoration: underline;;
}

.profil_menu{
background-color: #fefefe;

padding: 0;
margin-top: 5px;
border: 1px solid #D3D6DB;
}

.profil_menu ul{
list-style-type:none;
margin:0;
padding: 0;
}

.profil_menu ul li{
display:inline-block;
border-right:1px solid #DDDDDD;
padding:8px 10px;
}
.profil_menu ul li:hover, .profil_menu ul li.selected{

}

.profil_menu ul li:hover a, .profil_menu ul li.selected a{
color:@primary-color;
}

.profil_menu ul li a{
font-size:13px;
font-weight:bold;
color:#444444;
text-decoration:none;
padding:0;
}

.profil_infos_box{
margin-top:10px;
padding:10px;
border: 1px solid #D3D6DB;
border-radius:5px;
font-size:12px;
}

.profil_infos_box h4{
margin-bottom: 10px;
}

.profil_infos_box .libelle{
font-weight: normal;
color:#89919C;
}

.profil_infos_field_area{
padding:0px;
}

/* Mur */

.container_wall{
	min-width:580px;
	padding:10px 0px;
}

.container_write_message{
	margin:5px 0px;
}

.container_photo_profil{
	display: inline-block;
	text-align: left;
	vertical-align: top;
}

.container_photo_profil_mini{
	width: 70px;
}

.container_photo_profil_micro{
	width: 44px;
}

.container_wall_content{
	text-align: left;
	vertical-align: top;
	display: inline-block;
	width: 87%;
}

.container_wall_content_mini{
	/*margin-left: 75px;*/
}

.container_wall_content_micro{
	/*margin-left: 50px;*/
}

.container_wall_right_actions{
	width:30px;
	display:none;
}

.wall_item:hover .container_wall_right_actions{
	display:inline-block;
}

.mini_photo_profil{
	width:50px;
	height:50px;
}

.micro_photo_profil{
	width:34px;
	height:34px;
}

.wall_item{
	padding:0px 0px;
	margin:5px 0px 10px 0px;
	border-bottom: 1px solid #D3D6DB;
}

.wall_item_headtext{
color:#808080;
}

.wall_item_date{
color:#999999;
}

.wall_item_zone_comment{
background:#f8f8f8;
padding:1px 7px;
margin:0px 0px 10px 0px;
}

.wall_item_actions{
	font-size:11px;
	line-height: 15px;
	padding:3px 0px;
}

.wall_item_actions a{
	text-decoration:none;
}

.wall_text{
	font-size:12px;
}

.wall_item_comments{
	margin:5px 0px;
}

.wall_item_write_comment{
	margin:5px 0px;
}

.write_comment{
	margin:5px 0px;
	/*width:100%;*/
	width:90%;
	height:20px;
}

.write_comment_btn{
	margin: 6px 0px 0px 0px;
}

.wall_comment_date{
color:#808080;
}

.more_comment{
margin-top:10px;
clear:both;
padding:7px 10px;
background:#F5F5F5;
width:calc(100% - 20px);
font-size:12px;
}

.more_comment a{
text-decoration: none;
}

.comment_link_next{
text-decoration: none;
font-size:11px;
}


/* Annuaire */
.fullname_area{
margin-bottom:10px;
}

.fullname_area a{
font-size:13px;
}

.info_area{
margin:5px 0px;
font-size:12px;
}
