.home_blog p.h2,
.home_agenda p.h2,
.home_references p.h2,
.home_text p.h2,
.home_circle p.h2 {
  font-family: @font1;
  color:@color1;
  font-size:48px;
  font-weight:normal;
  margin:10px 0 20px 0;
}

.home_alternate_bg{
  background-color:@home-alternate-bg;
}

.home_primary_bg{
  background-color:@color-primary;

  p.h2{
    color:#ffffff;
  }
}

.home_secondary_bg{
  background-color:@color-secondary;

  p, p.h2, a{
    color:#ffffff;
  }

}

/* HOME AGENDA */

.home_agenda{
  padding-top:20px;
  padding-bottom:20px;
}

.home_agenda p.h2{
  font-family: @font1;
  color:#000;
  font-size:72px;
  font-weight:300;
  text-align: center;
}

.home_agenda p.h3{
  font-family: @font1;
  color:#000;
  font-size:30px;
  font-weight:300;
}

.home_agenda p.h3 a{
	text-decoration: none;
	color:@color1;
}

.home_agenda p.lieu{
	font-family: @font1;
  color:#000;
  font-size:24px;
  font-weight:300;
}

.home_agenda p{
	color:#999;
  font-size:18px;
  margin:20px 0px;
}

.home_agenda .date-container {
    background: @color1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.home_agenda .jour {
    font-family: @font1;
    font-weight:300;
    color: #ffffff;
    font-size: 72px;
    line-height: 72px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
}

.home_agenda .mois {
    color: #ffffff;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
}

.home_agenda a.btn-primary{
  font-family: @font1;
  font-weight: 300;
  background: transparent;
  border:2px solid @color-primary;
  font-size: 20px;
  color:@color-primary;
  padding:5px 30px;
}

.home_agenda a.btn-primary:hover{
  background: @color-primary;
  border:2px solid @color-primary;
  color:#ffffff;
}

/* HOME COMPETENCES */

.home_competences{
  padding-top:20px;
  padding-bottom:20px;
}

.home_competences p.h2{
  font-family: @font1;
  color:#000;
  font-size:72px;
  font-weight:300;
  text-align: center;
}

.home_competences p.h2 span{
  color:#000;
}

.home_competences p.h3{
  font-family: @font1;
  color:#000;
  font-size:30px;
  font-weight:300;
  text-align: center;
}

.home_competences p.h3 a{
  color:#000;
}

.home_competences p{
  color:#999;
  font-size:18px;
  margin:20px 0px;
  text-align: center;
}

.home_competences a.btn-primary{
  font-family: @font1;
  font-weight: 300;
  background: transparent;
  border:2px solid @color-primary;
  font-size: 20px;
  color:@color-primary;
  padding:5px 30px;
}

.home_competences a.btn-primary:hover{
  background: @color-primary;
  border:2px solid @color-primary;
  color:#ffffff;
}

/* HOME BLOG */

.home_blog{
  padding-top:20px;
  padding-bottom:20px;
}

.home_blog p.h2{
  font-family: @font1;
  color:#000;
  font-size:72px;
  font-weight:300;
  text-align: center;
}

.home_blog p.h2 span{
  color:#000;
}

.home_blog p.h3{
  font-family: @font1;
  color:#000;
  font-size:30px;
  font-weight:300;
  text-align: center;
}

.home_blog p.h3 a{
  color:#000;
}

.home_blog p{
  color:#999;
  font-size:18px;
  margin:20px 0px;
  text-align: center;
}

.home_blog a.btn-primary{
  font-family: @font1;
  font-weight: 300;
  background: transparent;
  border:2px solid @color-primary;
  font-size: 20px;
  color:@color-primary;
  padding:5px 30px;
}

.home_blog a.btn-primary:hover{
  background: @color-primary;
  border:2px solid @color-primary;
  color:#ffffff;
}

/* HOME RESEAUX SOCIAUX */

.home_social{
	background:#0064AC;
	padding:20px 0px;
}

.home_social p{
	color:#ffffff;
	font-size:24px;
	text-align: left;
	line-height:48px;
	padding:0px 20px 0px 0px;
	margin:0px;
}

.home_social span{
	float:left;
	color:#ffffff;
	font-size:24px;
	text-align: left;
	line-height:48px;
	padding:0px 20px 0px 0px;
}

.home_social input{
	height:40px;
	color:#777777;
	font-size:14px;
	padding:5px 10px;
	border:1px solid #ffffff;
	margin:0px 10px 0px 0px;
}

.reseaux_sociaux ul{
	float:left;
	margin:0;
	padding:0;
	list-style: none;
	text-align:center;
}

.reseaux_sociaux li{
	display:inline-block;
	width:48px;
	height:48px;
	margin:0px 10px 0px 0px;
}

.reseaux_sociaux li a{
	display:inline-block;
	width:48px;
	height:48px;
}

.home_social .newsletter input[type="button"]{
	display:inline-block;
	text-align:center;
	background:@color1;
	color:#ffffff;
	font-size:14px;
	font-weight:bold;
	line-height:40px;
	padding:0px 40px;
	border:none;

	transition: background-color 200ms linear;
}

.home_social .newsletter input[type="button"]:hover{
	background:#222222;
	text-decoration:none;
}

/* HOME GALERIE */

.home_galerie p.h2{
  font-family: @font1;
  color:#000;
  font-size:72px;
  font-weight:300;
  text-align: center;
  margin-bottom: 50px;
}

.home_galerie .col-galerie{
  padding:0px;
}

.home_galerie .galerie-thumnail{
  height:380px;
}

.home_galerie .bloc-galerie{
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.home_galerie .bloc-galerie img{
  max-width: 100%;
  max-height: none;
  -webkit-transition: all 0.5s linear;
          transition: all 0.5s linear;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
}

.home_galerie .bloc-galerie .galerie-caption{
  opacity: 0;
  position: absolute;
  top:0px;
  bottom: 0px;
  width: 100%;
  background-color: rgba(28, 125, 207, 0.75);

  transition:all 0.2s ease-in;
}

.home_galerie .galerie-thumnail .galerie-caption{
  padding-top: 13rem;
}

.home_galerie .bloc-galerie .galerie-caption p{
  color: #fff;
  text-align: center;
  font-family: @font1;
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
  padding:0;
  margin:0;
}

.home_galerie .bloc-galerie:hover .galerie-caption{
  opacity:1;
}

.home_galerie .bloc-galerie:hover img{
  -webkit-transform: scale3d(1.2, 1.2, 1);
          transform: scale3d(1.2, 1.2, 1);
}

/* HOME GALERIE 2 */

.home_galerie_2{
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: @color-primary;
}

.home_galerie_2 p.h2{
  font-family: @font1;
  color:#fff;
  font-size:72px;
  font-weight:300;
  text-align: center;
  margin-bottom: 50px;
}

.home_galerie_2 img{
  border:2px solid #fff;
}

.home_galerie_2 .carousel-control{
  position: static;
  color: #fff;
  text-shadow:none;
  margin:10px 0 0 0;
  display: inline-block;
  font-size: 60px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border:2px solid #fff;
  border-radius: 100px;
  background: transparent !important;
}

.home_galerie_2 .carousel-control:hover,
.home_galerie_2 .carousel-control:focus{
  border:2px solid #fff;
  color:#fff
}

.home_galerie_2 .carousel-control .fa-angle-left{
  position: relative;
  left:0px;
  top:-5px;
  margin: 0;
}

.home_galerie_2 .carousel-control .fa-angle-right{
  position: relative;
  right:0px;
  top:-5px;
  margin: 0;
}

.home_galerie_2 .carousel-indicators{
  position: static;
  margin:0;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.home_galerie_2 .carousel-indicators .active{
  background-color: rgba(255, 255, 255, 0.5);
}

.home_galerie_2 a.btn-primary{
  background: transparent;
  border:2px solid #fff;
  font-size: 20px;
  color:#fff;
  padding:5px 30px;
}

.home_galerie_2 a.btn-primary:hover{
  background: #fff;
  border:2px solid #fff;
  color:@color-primary;
}


@media(min-width:992px){
  .home_responsive img{
    position:absolute;
    max-width:none;
  }

  .home_responsive h2{
    font-size:100px;
  }

  .home_responsive h2 span{
    font-size: 72px;
  }
}
