.btn-primary{
  background:@color1;
  border-color:@color1;
  color: #ffffff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary{
  background:@color2;
  border-color:@color2;
  color: #ffffff;
}

span.bouton {
display:inline-block;
line-height:30px;
padding:0 0 0 10px;
}
span.bouton a {
color:#FFFFFF;
display:inline-block;
font-size:11px;
line-height:20px;
padding:0 10px 0 0;
text-decoration:none;
}
