.wai-zoom span {
  color: #2773AE;
}
.wai-zoom span:hover {
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
}
.wai-zoom-moins {
  font-size: 80%;
}
.wai-zoom-plus {
  font-size: 120%;
}

.wai-zoom-1  p, .wai-zoom-1  li, .wai-zoom-1  table, .wai-zoom-1  label { font-size: 0.7em }
.wai-zoom-2  p, .wai-zoom-2  li, .wai-zoom-2  table, .wai-zoom-2  label { font-size: 0.7em }
.wai-zoom-3  p, .wai-zoom-3  li, .wai-zoom-3  table, .wai-zoom-3  label { font-size: 0.7em }
.wai-zoom-4  p, .wai-zoom-4  li, .wai-zoom-4  table, .wai-zoom-4  label { font-size: 0.8em }
.wai-zoom-5  p, .wai-zoom-5  li, .wai-zoom-5  table, .wai-zoom-5  label { font-size: 0.9em }
.wai-zoom-6  p, .wai-zoom-6  li, .wai-zoom-6  table, .wai-zoom-6  label { font-size: 1.0em }
.wai-zoom-7  p, .wai-zoom-7  li, .wai-zoom-7  table, .wai-zoom-7  label { font-size: 1.2em }
.wai-zoom-8  p, .wai-zoom-8  li, .wai-zoom-8  table, .wai-zoom-8  label { font-size: 1.4em }
.wai-zoom-9  p, .wai-zoom-9  li, .wai-zoom-9  table, .wai-zoom-9  label { font-size: 1.6em }
.wai-zoom-10 p, .wai-zoom-10 li, .wai-zoom-10 table, .wai-zoom-10 label { font-size: 1.8em }
.wai-zoom-11 p, .wai-zoom-11 li, .wai-zoom-11 table, .wai-zoom-11 label { font-size: 2.0em }
