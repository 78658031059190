img{
border:none;
max-width: 100%;
}

.img_left{
float:left;
padding:0px 10px 10px 0px;
}

.img_right{
float:right;
padding:0px 0px 10px 10px;
}
