/*---------------------------CHOIX LANGUE---------------------------*/

#choix_langue{
	float:left;
	width:50px;
	height:18px;
	padding:3px 0px;
}

#choix_langue a.drapeau_francais{
	float:left;
	width:18px;
	height:18px;
	background:url('@{global-img-path}/flags/drapeau_francais.png') no-repeat left top;
	margin:0px 5px 0px 0px;
}

#choix_langue a.drapeau_francais_nb{
	float:left;
	width:18px;
	height:18px;
	background:url('@{global-img-path}/flags/drapeau_francais_nb.png') no-repeat left top;
	margin:0px 5px 0px 0px;
}

#choix_langue a.drapeau_anglais{
	float:left;
	width:18px;
	height:18px;
	background:url('@{global-img-path}/flags/drapeau_anglais.png') no-repeat left top;
}

#choix_langue a.drapeau_anglais_nb{
	float:left;
	width:18px;
	height:18px;
	background:url('@{global-img-path}/flags/drapeau_anglais_nb.png') no-repeat left top;
}
