.phototheque_explorer_dossier:nth-child(3n+1),
.phototheque_explorer_photo:nth-child(3n+1) {
    clear: both;
}

.phototheque_explorer_dossier img,
.phototheque_explorer_photo img {
    background: #DDDDDD;
    max-width: 200px;
    max-height: 150px;
    min-width: 200px;
    min-height: 150px;
    display: block;
}

.phototheque_explorer_dossier .titre {
    font-size: 13px;
    text-decoration: none;
    color: #222222;
    display: block;
    width: 100%;
    padding: 3px 0px;
}

.phototheque_explorer_dossier .infos {
    color: #AAAAAA;
    font-size: 11px;
    width: 100%;
    display: block;
}

.phototheque_explorer_photo span {
    margin: 5px 0px;
    font-size: 11px;
    display: block;
    color: #666666;
}

.phototheque_vignettes {
    float: left;
    width: 100%;
    padding: 5px 0px;
}

.phototheque_vignette {
    float: left;
    padding: 5px;
}

.phototheque_vignette_dossier {
    float: left;
    width: 100%;
    padding: 5px 0px 10px 0px;
}

.phototheque_vignette_dossier a {
    background: none repeat scroll 0 0 #ffffff;
    border-radius: 3px 3px 3px 3px;
    color: #31579E;
    display: inline-block;
    font-size: 24px;
    line-height: 20px;
    padding: 0 10px 0;
    margin: 0 5px 10px 0;
    text-decoration: none;
    cursor: pointer;
}

a.bouton_selected {
    background: none repeat scroll 0 0 #31579E;
    border-radius: 3px 3px 3px 3px;
    color: #ffffff;
    display: inline-block;
    font-size: 24px;
    line-height: 20px;
    padding: 5px;
    text-decoration: none;
    cursor: pointer;
}

.bloc_phototheque_photos .titre {
    font-size: 14px;
}

.bloc_phototheque_photo {
    float: left;
    padding: 5px;
}
