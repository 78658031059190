.table-cart > tbody > tr > td {
  vertical-align: middle;
}
.table-cart > tbody > tr:last-child {
  border-bottom: 1px solid #ddd;
}
.table-cart > thead > tr > th.cart-col-picture,
.table-cart > tbody > tr > td.cart-col-picture {
  width: 60px;
}
.table-cart > thead > tr > th.cart-col-descriptif,
.table-cart > tbody > tr > td.cart-col-descriptif {
  width: 50%;
}
.table-cart > thead > tr > th.cart-col-delete,
.table-cart > tbody > tr > td.cart-col-delete {
  width: 50px;
}
@media (min-width: 992px) {
  .cart-totals {
    padding-right: 50px;
  }
}

.cart-price {
  font-size: 18px;
}
.cart-price-total {
  font-weight: bold;
  color: #DD0000;
}
.cart-qte,
.cart-qte {
  display:block;
  margin:-6px 0px;
  font-size: 16px
}
.cart-promo-error {
  color: #DD0000;
  font-weight: bold;
  margin-top: 10px;
}

.table-delivery > tbody > tr > td,
.table-payment > tbody > tr > td {
  vertical-align: middle;
}
.table-delivery > tbody > tr > td:first-child,
.table-payment > tbody > tr > td:first-child {
  width: 50px;
}
.table-payment label,
.table-delivery label {
  display: block;
}
.payment-label,
.delivery-label {
  font-size: 18px;
  font-weight: bold;
  color: #888888;
}

.panier_item_quantite {
  font-size: 12px;
  margin: 10px 0px;
}
.panier_item {
  float: left;
  margin: 0px 5px 0px 0px;
}
.panier_item_modif {
  float: left;
}
.panier_item_ajout {
  float: left;
  clear: both;
  margin: 10px 0px;
  width: 100%;
}
.dispo_produit {
  float: left;
  width: 100%;
  font-size: 12px;
}

div.bloc_recherche_catalogue {
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  clear: both;
  padding: 5px;
}
div.bloc_recherche_catalogue .vide {
  padding: 10px;
}
div.bloc_recherche_catalogue label {
	display: block;
}
div.bloc_recherche_produit{
  background-color: #f5f5f5;
  border-top: none;
  width: 100%;
  border-radius: 0 0 10px 10px;
  margin-bottom: 20px;
  clear: both;
}
div.bloc_recherche_bloc {
  padding: 20px;
}
div.bloc_recherche_colonne {
  width: 50%;
  float: left;
}
div.bloc_recherche_droite {
  border-left: 1px solid #CFCFCF;
}
div.bloc_recherche_titre{
background:#CFCFCF;line-height:32px;font-size:16px;font-weight:bold;padding-left:40px;
}
div.bloc_recherche_bouton{
text-align:center;padding-bottom:10px;
}
div.recherche_famille{
width:100%;padding:20px;
}
div.recherche_famille_colonne{
width:50%;float:left;
}
div.recherche_palier_prix{
width:100%;padding:20px;
}
div.recherche_palier_prix_colonne{
width:50%;float:left;
}
div.recherche_palier_prix_colonne label{
font-size: 11px;
}
div.fiche_produit{
margin-top:10px;width:100%;
}
div.fiche_produit div.photos_produit{
  margin:10px;
}
div.fiche_produit div.miniatures{
padding:10px 0px;width:100%;clear:both;
}
div.fiche_produit div.img_produit{
height:300px;margin:auto;
}
div.fiche_produit div.panier_produit{
  min-height:310px;
  background:#EDEDED;
  padding:10px;
  -moz-box-shadow: -4px 0px 8px -2px rgba(0,0,0,.2);
  -webkit-box-shadow: -4px 0px 8px -2px rgba(0, 0, 0, .2);
  box-shadow: -4px 0px 8px -2px rgba(0, 0, 0, .2);
}
div.fiche_produit div.infos_produit{
  margin-top:10px;
  margin-right:15px;
}
div.fiche_produit div.descriptif_produit{
clear:both;float:left;margin:10px;font-size:12px;
}
div.fiche_produit div.descriptif_produit span.titre_descriptif{
font-size:16px;color:#555555;display:block;border-bottom:1px solid #CFCFCF;padding:3px 0px;margin:3px 0px;
}
table.affiche_produit_ligne{
padding:5px;
}
table.affiche_produit_ligne td{
height:120px;
}
table.affiche_produit_ligne td{
border-bottom:1px solid #CFCFCF;
}
div.affiche_produit_grille{
padding:5px;
}
div.fiche_produit_grille{
float:left;width:225px;height:320px;margin:5px;border-bottom:1px solid #CFCFCF;
}
div.fiche_produit_grille div.grille_produit_image{
height:120px;width:120px;margin:auto;
}
div.fiche_produit_grille div.grille_produit_prix{
text-align:right;padding:10px;
}
div.fiche_produit_grille div.grille_produit_bouton{
text-align:center;bottom:0px;
}
div.ligne_produits{
width:100%;
}
div.ligne_catalogue{
background-color:#EFEFEF;width:100%;
}
div.ligne_catalogue_entete{
border-bottom:none;
}
div.ligne_catalogue_pied{
border-top:none;
}
div.ligne_catalogue_left{
float:left;margin-left:5px;padding:5px;width:280px;
}
div.ligne_catalogue_precedente{
float:left;padding:5px;width:125px;
}
div.ligne_catalogue_page{
float:left;padding:5px;width:80px;text-align:center;
}
div.ligne_catalogue_page select{
height:22px;
}
div.ligne_catalogue_suivante{
float:left;text-align:right;padding:5px;width:125px;
}
div.ligne_catalogue_right{
float:right;margin-right:5px;padding:5px;
}
input.btn_action,a.btn_action{
background-image:none;background-color:#31579E;border-radius:5px;border-style:none;border-width:0;box-shadow:0 1px 3px rgba(0,0,0,0.3);color:#FFFFFF;cursor:pointer;display:inline-block;padding:8px 12px 8px;position:relative;text-decoration:none;font-size:12px;line-height:1;
}
input.btn_action:hover,a.btn_action:hover{
background-color:#527ED2;
}
input.btn_action.btn_disabled,a.btn_action.btn_disabled{
background-color:#BBBBBB;color:#555555;
}
input.btn_action.btn_disabled:hover,a.btn_action.btn_disabled:hover{
background-color:#DDDDDD;
}
input.btn_action.btn_large,a.btn_action.btn_large{
font-size:16px;font-weight:bold;line-height:2;padding:8px 30px 8px;border-radius:10px;
}
span.titre_produit{
font-size:1em;color:#355971;
}
span.desc_produit{
font-size:0.8em;color:#666;
}
span.ref_produit{
font-size:0.8em;font-style:italic;color:#333;
}
span.info_produit_indispo{
font-size:0.8em;font-style:italic;color:#333;
}
#commande_identification,
#commande_inscription{
    background: none repeat scroll 0 0 #fff;
    border-radius: 10px;
    padding: 5px 20px;
}
.type_inscription{
color:#355971;margin-left:10px;
}
#zone_type_inscription{
background:#F6F6F6;float:left;width:80%;padding:10px 0px 10px 50px;
}
#inscription_particulier,#inscription_pro{
float:left;width:50%;
}
/* Popup ajout panier */
#valideAjoutPanier-container{
height:150px;width:600px;
}
p.erreur_ajout_panier{
font-weight:bold;color:#FF0000;text-align:center;
}
.panier_etapes{
clear:both;float:left;width:852px;padding:0px 0px;margin:40px 0px 0px 0px;
}
.etape_precedente{
float:left;width:426px;padding:0px;
}
.etape_precedente p{
text-align:left;
}
.etape_suivante{
float:left;width:426px;padding:0px;
}
.etape_suivante p{
text-align:right;
}
a.bouton{
background:none repeat scroll 0 0 @primary-color;border-radius:3px 3px 3px 3px;color:#FFFFFF;display:inline-block;font-size:11px;line-height:20px;padding:0 10px 0;text-decoration:none;cursor:pointer;
}
a.bouton:hover{
text-decoration:underline;
}
/* Prix catalogue */
 span.prix_normal{
color:#31579E;font-weight:bold;
}
span.prix_normal span.prix_decimale{
font-size:24px;
}
span.prix_normal span.prix_flotante{
font-size:16px;
}
span.prix_normal span.prix_devise{
font-size:16px;vertical-align:super;
}
span.prix_promotion{
font-size:16px;color:#666666;text-decoration:line-through;
}

/* PAGINATION */
.row-pagination{
	margin:10px 0;
}

/* Attributs/Déclinaisons de produits */
.attribute-option {
    display: inline-block;
}
.attribute-color-option {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
input[type="radio"]:checked ~ .attribute-color-option,
input[type="radio"]:checked ~ .attribute-logo-option {
    border:black solid 2px;
}
.attribute-option input[type="radio"] {
    display: none;
}
.attributes-list {
    font-size: x-small;
}
.main-picture {
    position:relative;
}
.dynamic-attribute-view {
    border:solid black 1px;
    position: absolute;
    text-align: center;
}
/* Fin attribut */

.affiche_produit_grille .produit{
height:370px;
}

/**** NAV WIZARD - STEP ****/
.nav-wizard > li {
  float:none;
  display:inline-block;
  width:100%;
  margin-bottom: 10px;
  padding-right: 10px;
}
@media (min-width: 992px) {
  .nav-wizard > li {
    float: left;
    display:block;
    width:auto;
    margin-bottom: 0px;
    padding-right: 0px;
  }
}
.nav-wizard > li > a {
  position: relative;
  background-color: #eeeeee;
}
.nav-wizard > li > a .badge {
  margin-left: 3px;
  color: @color1;
  background-color: #fff;
}
.nav-wizard > li:not(:first-child) > a {
  padding-left: 34px;
}
.nav-wizard > li:not(:first-child) > a:before {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #ffffff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
.nav-wizard > li:not(:last-child) > a {
  margin-right: 6px;
}
.nav-wizard > li:not(:last-child) > a:after {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #eeeeee;
  position: absolute;
  content: "";
  top: 0;
  right: -20px;
  z-index: 2;
}
.nav-wizard > li:first-child > a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.nav-wizard > li:last-child > a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.nav-wizard > li.done:hover > a,
.nav-wizard > li:hover > a {
  background-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:before,
.nav-wizard > li:hover > a:before {
  border-right-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:after,
.nav-wizard > li:hover > a:after {
  border-left-color: #d5d5d5;
}
.nav-wizard > li.done > a {
  background-color: #e2e2e2;
}
.nav-wizard > li.done > a:before {
  border-right-color: #e2e2e2;
}
.nav-wizard > li.done > a:after {
  border-left-color: #e2e2e2;
}
.nav-wizard > li.active > a,
.nav-wizard > li.active > a:hover,
.nav-wizard > li.active > a:focus {
  color: #ffffff;
  background-color: @color1;
}
.nav-wizard > li.active > a:after {
  border-left-color: @color1;
}
.nav-wizard > li.active > a .badge {
  color: @color1;
  background-color: #ffffff;
}
.nav-wizard > li.disabled > a {
  color: #777777;
}
.nav-wizard > li.disabled > a:hover,
.nav-wizard > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: #eeeeee;
  cursor: default;
}
.nav-wizard > li.disabled > a:before {
  border-right-color: #eeeeee;
}
.nav-wizard > li.disabled > a:after {
  border-left-color: #eeeeee;
}
.nav-wizard.nav-justified > li {
  float: none;
}
.nav-wizard.nav-justified > li > a {
  padding: 10px 15px;
}
@media (max-width: 768px) {
  .nav-wizard.nav-justified > li > a {
    border-radius: 4px;
    margin-right: 0;
  }
  .nav-wizard.nav-justified > li > a:before,
  .nav-wizard.nav-justified > li > a:after {
    border: none !important;
  }
}

.dpd-pickup-point:hover, .dpd-pickup-point:hover p{
  background:@color3;
  color:#ffffff;
}

.dpd-pickup-point.active, .dpd-pickup-point.active p{
  background:@color1;
  color:#ffffff;
}
