#alerte_uti_secondaire {
  width:100%;
  background-color:#444;
  color:#ffffff;
  padding:7px 3% 7px 3%;
  font-size:11px;
}

#alerte_uti_secondaire a {
  background:#000000;
  border-radius:5px;
  padding:7px;
  text-decoration:none;
  line-height:20px;
  color:#FFFFFF;
}

.alert-cookie {
  margin-bottom: 0px;
  border-radius: 0;
  text-align: center;
}
.alert-cookie a {
  text-decoration: underline;
}

#top{
  background:@heading-bg;
}

#heading{
  background:@heading-bg;
}

a.logo img{
max-height:130px;
}

.message_site{
height:30px;
text-align:center;
line-height:30px;
font-size:16px;
background:@color1;
color:#ffffff;
border-bottom:1px solid #dddddd;
}

#heading_left{
  text-align:center;
}

p.date{
  text-align:center;
}

#heading_right p{
  text-align: right;
}

#heading_right .btn-contact{
  font-family: @font1;
  background: transparent;
  border:2px solid @color-primary;
  font-size: 18px;
  font-weight: 300;
  color:@color-primary;
  padding:5px 15px;
  line-height: 30px;

  @media(min-width:992px){
    margin-left: 15px;
  }
}

#heading_right .btn-contact .fa{
  color:@color-primary;
  margin-right: 5px;
}

#heading_right .btn-contact:hover{
  background: @color-primary;
  color:#fff;
}

#heading_right .btn-contact:hover .fa{
  color:#fff;
}

#heading_right .btn-phone{
  background: transparent;
  border:2px solid transparent;
  font-size: 24px;
  font-weight: 300;
  color:@color-primary;
  padding:5px 15px;
  line-height: 30px;
}

#heading_right .btn-phone .fa{
  color:@color-primary;
}

#heading_right .btn-phone:hover{
  background: transparent;
  border:2px solid transparent;
  color:#666;
}

/*---------------------- SOUS BANDEAU -------------------------*/

.sous_bandeau {
  height: 155px;
  background: none no-repeat top center;
}

.sous_bandeau_content {
  position: absolute;
  left: 0px;
  top: 0px;
  float: left;
  height: 250px;
  width: 400px;
  padding: 50px 50px 50px 0;
  margin: 0px;
  // background: @color1 no-repeat left center;
}

.sous_bandeau_content p {
  color: #ffffff;
  font-size: 18px;
}


@media(min-width:992px){

  #heading_left{
    text-align:left;
  }

  p.date{
    text-align:right;
  }
}
