.annonce{
float:left; width:29%; cursor:pointer; padding:10px; background:#EEEEEE;margin:1%;
}

.annonce:hover{
background:#DDDDDD;
}

.annonce img{
max-width: 100px; max-height: 100px; float:left; margin-right:20px;
}

.annonce .photo, #fiche_annonce .photo{
width:150px;float:left;
}

.detail_annonce{
float:left;
}

.detail_annonce a{
text-decoration:none;
}

.detail_annonce a:hover{
text-decoration:none;
}

.detail_annonce span{
display:block; font-size:12px;
}

.detail_annonce span.prix{
font-weight:bold; font-size:14px; margin:10px 0px 5px 0px;
}

#fiche_annonce{
width:100%;float:left;clear:both;
}

#fiche_annonce img{
max-width: 150px; max-height: 150px; float:left; margin-right:20px;
}

#fiche_annonce .infos{
font-style:italic;
}
