#main-carousel{

}

#main-carousel .carousel-caption{
  top:10%;
  bottom: auto;
  background-color: rgba(25, 125, 207, 0.7)
}

.full-screen {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#main-carousel .slide_deco{
  width: 100%;
  background: rgba(0,0,0,0.3);
  position: absolute;
  z-index: 4;
}

#main-carousel p.h2{
  font-family: @font1;
  font-weight: 600;
  color:#fff;
  font-size: 72px;
  text-shadow:none;
  margin-bottom: 30px;
}

#main-carousel p{
  font-family: @font1;
  color:#fff;
  font-size: 72px;
  text-shadow:none;
  margin-bottom: 30px;
}

#main-carousel p.lien a{
  font-family: @font1;
  font-weight: normal;
  background: transparent;
  border:2px solid #fff;
  font-size: 24px;
  color:#fff;
  padding:8px 30px;
  text-decoration: none;
  border-radius: 5px;
  text-shadow:none;
}

#main-carousel p.lien a:hover,
#main-carousel p.lien a:focus,
#main-carousel p.lien a:active{
  color:#000;
  background-color: #fff;
  border:1px solid #fff;
}

.carousel-indicators li{
  border:none;
  background: #fff;
}

.carousel-indicators .active{
  background:@color1;
}
