.form_poll label{
    font-size: 17px;
    margin:0 0 10px 0;
}

.form_poll .field{
    margin:0 0 15px 0;
    padding:0 0 10px 0;
    border-bottom: 1px dotted #bbbbbb;
}

.form_poll .field .content p{
    margin:0 0 10px 0;
}

.form_poll .field .content label{
    font-weight: normal;
    font-size: 14px;
    margin:0;
}
