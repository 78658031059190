div.actions{
padding:10px 0px;
text-align:center;
}
span.obligatoire{
font-size:11px;color:red;
}
div.obligatoire{
font-size:11px;padding:10px 0px;color:red;
}

.control-label{
  font-size:12px;
}
