a.info {
color:#5C8EC1;
font-size:12px;
font-weight:bold;
position:relative;
text-decoration:none;
}
a.info span {
display:none;
}
a.info:hover {
background:transparent none repeat scroll 0 0;
cursor:help;
z-index:500;
}
a.info:hover span {
background-color:#FAFAFA;
border:3px solid #CCCCCC;
color:black;
display:inline;
font-size:12px;
font-weight:normal;
left:20px;
padding:5px;
position:absolute;
top:30px;
white-space:nowrap;
}
