.autocomplete-suggestions {
    border: 1px solid #999;
    background: #FFF;
    cursor: default;
    overflow: auto;
    -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
    -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
    box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
}

.autocomplete-suggestion {
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-no-suggestion {
    padding: 2px 5px;
}

.autocomplete-selected {
    background: @color-primary;
    color:#ffffff;
}

.autocomplete-suggestions strong {
    font-weight: normal;
    color: @color-secondary;
}
