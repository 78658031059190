/* FOOTER */

html {
  position: relative;
  min-height: 100%;
}

body{
  margin-bottom:@footer-height;
}

footer {
  position: absolute;
  bottom: 0;
  height:@footer-height;
  width: 100%;
  height:@footer-height;
}

#bottom{
  margin:0px;
  background:@footer-bg;
}


.footer h2{
	font-family:open_sansregular;
	font-size:30px;
	font-weight:normal;
	text-align:center;
	margin:20px 0px;
}

.footer .btn-connexion{
  border:1px solid @color-primary;
  background-color: transparent;
  color:@color-primary;
}

.footer .btn-connexion:hover{
  border:1px solid @color-primary;
  background-color: @color-primary;
  color:#fff;
}

.footer .copyright {
  text-align: center;
  font-size: 13px;
  color:@footer-text-color;
}

.footer ul{
  margin:0;
  padding:0;
  list-style: none;
  text-align:left;
}

ul.social-networks {
  text-align:center;
}

ul.social-networks li {
  display: inline-block;
  height: 48px;
  margin: 0 10px 0 0;
  width: 48px;
}

ul.social-networks li a{
    display: inline-block;
    height: 48px;
    width: 48px;
    line-height: 48px;
    background: @color-primary;
    border-radius: 48px;
    text-align: center;
    color: #fff;
}

ul.social-networks li a .fa {
  font-size: 24px;
  line-height: 48px;
}

.footer p{
	color:@footer-text-color;
	text-align:center;
}

.footer .footer_liens li{
	text-align: center;
  margin-bottom: 5px;
}

.footer .footer_liens li a{
	.fa-icon;
  .fas;

  color:@color-primary;
	padding:0px 0px 0px 20px;
  text-decoration: none;
}

.footer_liens ul li a:before {
  content: @fa-var-chevron-right;
  color: #666;
  font-size: 12px;
}

.footer span.sep_v{
	color:@color1;
	padding:0 10px;
}

.footer span.sep_v:last-child{
	display:none;
}


@media(min-width:992px){

  .liens_footer p{
    text-align:left;
  }

  .copyright p{
    text-align:right;
  }
}
