/* MON ESPACE */
.espace_client_bouton{
	display: block;
	text-align:center;
	background: #ffffff;
	border-radius: 10px;
	padding:10px 20px;
	color:#777777;
	text-decoration: none;

	transition: background-color 200ms linear 0s;
}

.espace_client_bouton:hover{
	background: #dddddd;
	color:#000000;
	text-decoration: none;
}

.espace_client_bouton span{
	font-size:48px;
}
