/* ROBOTO */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    src: url('@{fonts-path}/Roboto-Thin-webfont.eot');
    src: url('@{fonts-path}/Roboto-Thin-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-Thin-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-Thin-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-Thin-webfont.svg#Roboto-Thin-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 200;
    src: url('@{fonts-path}/Roboto-ThinItalic-webfont.eot');
    src: url('@{fonts-path}/Roboto-ThinItalic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-ThinItalic-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-ThinItalic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-ThinItalic-webfont.svg#Roboto-ThinItalic-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('@{fonts-path}/Roboto-Light-webfont.eot');
    src: url('@{fonts-path}/Roboto-Light-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-Light-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-Light-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-Light-webfont.svg#Roboto-Light-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('@{fonts-path}/Roboto-LightItalic-webfont.eot');
    src: url('@{fonts-path}/Roboto-LightItalic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-LightItalic-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-LightItalic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-LightItalic-webfont.svg#Roboto-LightItalic-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('@{fonts-path}/Roboto-Regular-webfont.eot');
    src: url('@{fonts-path}/Roboto-Regular-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-Regular-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-Regular-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-Regular-webfont.svg#Roboto-Regular-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('@{fonts-path}/Roboto-Italic-webfont.eot');
    src: url('@{fonts-path}/Roboto-Italic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-Italic-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-Italic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-Italic-webfont.svg#Roboto-Italic-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('@{fonts-path}/Roboto-Medium-webfont.eot');
    src: url('@{fonts-path}/Roboto-Medium-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-Medium-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-Medium-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-Medium-webfont.svg#Roboto-Medium-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('@{fonts-path}/Roboto-MediumItalic-webfont.eot');
    src: url('@{fonts-path}/Roboto-MediumItalic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-MediumItalic-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-MediumItalic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-MediumItalic-webfont.svg#Roboto-MediumItalic-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    src: url('@{fonts-path}/Roboto-Bold-webfont.eot');
    src: url('@{fonts-path}/Roboto-Bold-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-Bold-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-Bold-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-Bold-webfont.svg#Roboto-Bold-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    src: url('@{fonts-path}/Roboto-BoldItalic-webfont.eot');
    src: url('@{fonts-path}/Roboto-BoldItalic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-BoldItalic-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-BoldItalic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-BoldItalic-webfont.svg#Roboto-BoldItalic-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('@{fonts-path}/Roboto-Black-webfont.eot');
    src: url('@{fonts-path}/Roboto-Black-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-Black-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-Black-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-Black-webfont.svg#Roboto-Black-webfont') format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('@{fonts-path}/Roboto-BlackItalic-webfont.eot');
    src: url('@{fonts-path}/Roboto-BlackItalic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/Roboto-BlackItalic-webfont.woff') format('woff'),
         url('@{fonts-path}/Roboto-BlackItalic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/Roboto-BlackItalic-webfont.svg#Roboto-BlackItalic-webfont') format('svg');
}


/* ROBOTO CONDENSED */

@font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 300;
    src: url('@{fonts-path}/RobotoCondensed-Light-webfont.eot');
    src: url('@{fonts-path}/RobotoCondensed-Light-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/RobotoCondensed-Light-webfont.woff') format('woff'),
         url('@{fonts-path}/RobotoCondensed-Light-webfont.ttf') format('truetype'),
         url('@{fonts-path}/RobotoCondensed-Light-webfont.svg#RobotoCondensed-Light-webfont') format('svg');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 300;
    src: url('@{fonts-path}/RobotoCondensed-LightItalic-webfont.eot');
    src: url('@{fonts-path}/RobotoCondensed-LightItalic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/RobotoCondensed-LightItalic-webfont.woff') format('woff'),
         url('@{fonts-path}/RobotoCondensed-LightItalic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/RobotoCondensed-LightItalic-webfont.svg#RobotoCondensed-LightItalic-webfont') format('svg');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 400;
    src: url('@{fonts-path}/RobotoCondensed-Regular-webfont.eot');
    src: url('@{fonts-path}/RobotoCondensed-Regular-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/RobotoCondensed-Regular-webfont.woff') format('woff'),
         url('@{fonts-path}/RobotoCondensed-Regular-webfont.ttf') format('truetype'),
         url('@{fonts-path}/RobotoCondensed-Regular-webfont.svg#RobotoCondensed-Regular-webfont') format('svg');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 400;
    src: url('@{fonts-path}/RobotoCondensed-Italic-webfont.eot');
    src: url('@{fonts-path}/RobotoCondensed-Italic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/RobotoCondensed-Italic-webfont.woff') format('woff'),
         url('@{fonts-path}/RobotoCondensed-Italic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/RobotoCondensed-Italic-webfont.svg#RobotoCondensed-Italic-webfont') format('svg');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: normal;
    font-weight: 600;
    src: url('@{fonts-path}/RobotoCondensed-Bold-webfont.eot');
    src: url('@{fonts-path}/RobotoCondensed-Bold-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/RobotoCondensed-Bold-webfont.woff') format('woff'),
         url('@{fonts-path}/RobotoCondensed-Bold-webfont.ttf') format('truetype'),
         url('@{fonts-path}/RobotoCondensed-Bold-webfont.svg#RobotoCondensed-Bold-webfont') format('svg');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-style: italic;
    font-weight: 600;
    src: url('@{fonts-path}/RobotoCondensed-BoldItalic-webfont.eot');
    src: url('@{fonts-path}/RobotoCondensed-BoldItalic-webfont.eot') format('embedded-opentype'),
         url('@{fonts-path}/RobotoCondensed-BoldItalic-webfont.woff') format('woff'),
         url('@{fonts-path}/RobotoCondensed-BoldItalic-webfont.ttf') format('truetype'),
         url('@{fonts-path}/RobotoCondensed-BoldItalic-webfont.svg#RobotoCondensed-BoldItalic-webfont') format('svg');
}

/* AUTRES */

@font-face {
    font-family: 'open_sansregular';
    src: url('@{fonts-path}/OpenSans-Regular-webfont.eot');
    src: url('@{fonts-path}/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('@{fonts-path}/OpenSans-Regular-webfont.woff') format('woff'),
         url('@{fonts-path}/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('@{fonts-path}/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'open_sansregular';
    src: url('@{fonts-path}/OpenSans-Regular-webfont.ttf') format('truetype');
  }
}

@font-face {
    font-family: 'SourceSansProLight';
    src: url('@{fonts-path}/sourcesanspro-light.eot');
    src: url('@{fonts-path}/sourcesanspro-light.eot') format('embedded-opentype'),
         url('@{fonts-path}/sourcesanspro-light.woff') format('woff'),
         url('@{fonts-path}/sourcesanspro-light.ttf') format('truetype'),
         url('@{fonts-path}/sourcesanspro-light.svg#SourceSansProLight') format('svg');
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'SourceSansProLight';
    src: url('@{fonts-path}/sourcesanspro-light.svg#SourceSansProLight') format('svg');
  }
}
